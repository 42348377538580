import { Button, Modal, ModalProps, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Tree } from "antd";
import type { DataNode, TreeProps } from "antd/es/tree";
interface IProps extends ModalProps {
  treeData: DataNode[] | undefined;
  currentCheckedKeys?: number[];
  onAssign: (selectedKeys: number[]) => void;
  loading?: boolean;
}
export const AssignActionModal: FC<IProps> = (props) => {
  const {
    treeData,
    currentCheckedKeys,
    title,
    onCancel,
    onAssign,
    loading,
    confirmLoading,
  } = props;
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
  const Footer: FC = () => {
    return (
      <div className="flex justify-between items-center p-[]">
        <Button
          type="text"
          onClick={onCancel}
          className="h-[30px] px-[12px] py-[6px] rounded-[6px] text-[10px]"
        >
          Close
        </Button>
        <Button
          type="primary"
          onClick={() => onAssign(checkedKeys)}
          className="h-[30px] px-[12px] py-[6px] rounded-[6px] text-[10px]"
          disabled={loading}
          loading={confirmLoading}
        >
          Assign
        </Button>
      </div>
    );
  };
  const Title: FC = () => {
    return <TitleStyle className=" divide-y-1"> {title} </TitleStyle>;
  };

  const onCheck: TreeProps["onCheck"] = (newCheckedKeys, info) => {
    const checked = info.node.checked;
    if (checked) {
      setCheckedKeys((prev) => prev.filter((key) => key !== info.node.key));
    } else {
      setCheckedKeys((prev) => [...prev, info.node.key as number]);
    }
  };
  useEffect(() => {
    if (currentCheckedKeys) {
      setCheckedKeys(currentCheckedKeys);
    }
  }, [currentCheckedKeys]);

  return (
    <Modal
      footer={<Footer />}
      width={308}
      className="assign-modal"
      closable={false}
      {...props}
      title={<Title />}
    >
      <div className="flex flex-col gap-[24px] mt-[16px]">
        <div className="h-[188px] overflow-y-auto">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <Spin />
            </div>
          ) : (
            <Tree
              checkable
              checkedKeys={checkedKeys}
              onCheck={onCheck}
              treeData={treeData}
              checkStrictly={true}
              showLine
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

const TitleStyle = styled.div`
  border-bottom: 1px solid #f3f4f5;
  color: var(--text-3, #5d6881);
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 8px;
`;
