import { App, Button, Col, Form, Row, Select, Skeleton, Space } from "antd";
import { FC, useEffect, useState } from "react";
import { FormItem } from "src/components/UiKit/FormItem";
import { SInput } from "src/components/UiKit/Input";
import { FormContainer } from "../styles/formContainer";
import { Icons } from "src/components/customIcons";
import { useAddInvoices } from "src/modules/addInvoice/context";
import { EditArticles } from "./editArticles";
import { FilePreview } from "src/components/UiKit/FilePreview";
import { IUpdateInvoice } from "src/services/Invoice/models";
import { InvoiceService } from "src/services/Invoice/Invoice.service";
import { useEditInvoice } from "../context";
import { IObject } from "src/models/interfaces";
import { DeleteArticleConfirm } from "./deleteArticleConfirm";
import { digitRule } from "src/helpers/digitRule";
import {
  AllArticlePath,
  DeleteInvoicePath,
  InvoiceAttqachmentPath,
  UpdateTotalInformationInvoicePath,
} from "src/services/Invoice/guardPath";
import { Guard } from "src/components/Guard";
import { InvoiceUrls } from "src/services/Invoice/urls";
import { uploadFile } from "src/helpers/uploadZipFile";
interface IProps {
  onCancelModal: () => void;
  header: string;
}
const { Option } = Select;
export const EditInvoiceForm: FC<IProps> = ({ onCancelModal, header }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreviewFile, setShowPreviewFile] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [showDeleteInvoice, setShowDeleteInvoice] = useState<boolean>(false);
  const { message } = App.useApp();
  const {
    value: {
      editInvoiceId,
      getCurrentInvoiceLoading,
      currentEditInvoice,
      showEditArticles,
      currentInvoiceAttachment,
    },
    dispatches: {
      setShowEditArticles,
      setCurrentInvoiceAttachment,
      setShowModal,
    },
    func: { refetchZipFileToggle, reset },
  } = useEditInvoice();
  const onFinish = async (values: IUpdateInvoice) => {
    if (!values || !editInvoiceId) return;
    try {
      setLoading(true);
      const reqBody: IObject = {};
      Object.keys(values).forEach((key) => {
        reqBody[key] = +values[key];
      });
      const { UpdateInvoice } = new InvoiceService();
      const res = await UpdateInvoice(editInvoiceId, reqBody as IUpdateInvoice);
      if (res) {
        refetchZipFileToggle();
        setEditMode(false);
        message.success("invoice updated successfully");
      }
    } catch (err) {
      console.log(err);
      message.error("invoice updated failed");
    } finally {
      setLoading(false);
    }
  };
  const [editInvoiceForm] = Form.useForm();
  const {
    value: { allCurrency },
  } = useAddInvoices()!;
  useEffect(() => {
    if (!getCurrentInvoiceLoading && currentEditInvoice) {
      editInvoiceForm.setFieldsValue({
        shipManagementTotlaAmount: currentEditInvoice.shipManagementTotlaAmount,
        supplireToTalAmount: currentEditInvoice.supplireToTalAmount,
        invoiceCurrencyId: currentEditInvoice.invoiceCurrencyId,
        tiaShipManagementPCurreccny:
          currentEditInvoice.tiaShipManagementPCurreccny,
        discount: currentEditInvoice.discount,
        tax: currentEditInvoice.tax,
        supervisorRate: currentEditInvoice.supervisorRate,
      });
    }
  }, [
    allCurrency,
    currentEditInvoice,
    editInvoiceForm,
    getCurrentInvoiceLoading,
  ]);
  const addFileOnChange = async (file: Blob | null) => {
    if (!editInvoiceId || !file) return;
    try {
      setUploadLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      const upload = await uploadFile({
        newFile: file,
        callbackUrl: InvoiceUrls.Invoice.concat(
          `${editInvoiceId}${InvoiceUrls.InvoiceAttqachment}`
        ),
        method: "PATCH",
        setProgress,
      });
      // const res = await InvoiceAttqachment(editInvoiceId, formData, config);
      if (upload) {
        message.success("Invoice attachment uploaded successfully");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64 = reader.result;
          setCurrentInvoiceAttachment(base64 as string);
        };
        return;
      }
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setUploadLoading(false);
    }
  };
  if (getCurrentInvoiceLoading) {
    return (
      <Skeleton active>
        <Skeleton active title />
        <Skeleton active avatar />
        <Skeleton active paragraph />
        <Skeleton active round />
        <Skeleton active avatar />
        <Skeleton active loading />
      </Skeleton>
    );
  }

  const confirmDelete = async () => {
    if (!editInvoiceId) return;
    setDeleteLoading(true);
    try {
      const { DeleteInvoice } = new InvoiceService();
      const res = await DeleteInvoice(editInvoiceId);
      if (res && res.status === 200) {
        setShowDeleteInvoice(false);
        setShowModal(false);
        editInvoiceForm.resetFields();
        refetchZipFileToggle();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDeleteLoading(false);
    }
  };
  return (
    <Form onFinish={onFinish} form={editInvoiceForm}>
      <FormContainer>
        <Col span={24}>
          <div className="invoice-base-info">
            you can edit invoice’s Total information .
          </div>
          <Row gutter={32}>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"shipManagementTotlaAmount"}
                label="Requester total amount"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput
                  placeholder="insert"
                  type="text"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
              <FormItem
                name={"supplireToTalAmount"}
                label="Supplier total amount"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput
                  placeholder="insert"
                  type="text"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
              <FormItem
                name={"invoiceCurrencyId"}
                label="Invoice currency"
                rules={[{ required: true }]}
                required={false}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="select"
                  optionLabelProp="label"
                  showSearch
                  className="selectOption"
                  disabled={!editMode}
                >
                  {allCurrency.map((item, index) => (
                    <Option value={item.id} label={item.shortName} key={index}>
                      <Space>{item.shortName}</Space>
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem
                name={"tiaShipManagementPCurreccny"}
                label="Total invoice amount req. pay currency"
                rules={[{ required: true }]}
                required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  type="text"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
            </Col>
            <Col span={12} className="mt-[16px]">
              <FormItem
                name={"discount"}
                label="Discount"
                rules={[{ required: true }, digitRule]}
                required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  type="text"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
              <FormItem
                name={"tax"}
                label="Tax"
                rules={[{ required: true }, digitRule]}
                required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
              <FormItem
                name={"supervisorRate"}
                label="Supervisor Rate"
                rules={[{ required: true }, digitRule]}
                required={false}
              >
                <SInput
                  style={{ width: "100%" }}
                  placeholder="insert"
                  disabled={!editMode}
                  numbermode
                />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={32} className="">
            <Col span={12}>
              <div className="attachment">
                <div>Attachment Pdf file.</div>
                <div>
                  <div
                    className="attachmentHolder"
                    onClick={() => setShowPreviewFile(true)}
                  >
                    <div className="attachmentBg">{<Icons name={"Pdf"} />}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <Guard action={AllArticlePath}>
                <div className="attachment">
                  <div>Invoice’s excel file.</div>
                  <div>
                    <div
                      className="attachmentHolder"
                      onClick={() => {
                        setShowEditArticles(true);
                      }}
                    >
                      <div className="attachmentBg">
                        <img
                          src="/assets/images/xls.png"
                          alt="icon"
                          width={32}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Guard>
            </Col>
          </Row>
        </Col>
        <EditArticles
          show={showEditArticles}
          onCancel={() => setShowEditArticles(false)}
          header={header}
        />
        <Col
          span={24}
          className="flex justify-end gap-[24px] mt-[100px] footer"
        >
          <Button
            onClick={() => {
              if (editMode) {
                setEditMode(false);
                reset();
              } else {
                onCancelModal();
                reset();
                editInvoiceForm.resetFields();
                setEditMode(false);
              }
            }}
            htmlType="button"
          >
            {editMode ? "Cancel" : "Close"}
          </Button>
          <Guard action={UpdateTotalInformationInvoicePath}>
            {editMode ? (
              <Button
                type="primary"
                htmlType="button"
                loading={loading}
                onClick={() => {
                  editInvoiceForm.submit();
                }}
              >
                Save
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="button"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </Button>
            )}
          </Guard>
          <Guard action={DeleteInvoicePath}>
            <Button
              htmlType="button"
              className={"w-[100px] bg-[#FF1414] text-[#ffff]"}
              onClick={() => {
                setShowDeleteInvoice(true);
              }}
            >
              Delete
            </Button>
          </Guard>
        </Col>
        <FilePreview
          show={showPreviewFile}
          onCancel={() => {
            setShowPreviewFile(false);
          }}
          referenceNumber={header}
          file={currentInvoiceAttachment !== ""}
          addFileOnChange={addFileOnChange}
          fileType="pdf"
          fileBase64={currentInvoiceAttachment}
          progress={progress}
          loading={uploadLoading}
          fileName={""}
          actions={{
            edit: InvoiceAttqachmentPath,
            editWithFile: InvoiceAttqachmentPath,
          }}
        />
      </FormContainer>
      <DeleteArticleConfirm
        loading={deleteLoading}
        onConfirm={confirmDelete}
        open={showDeleteInvoice}
        onCancel={() => setShowDeleteInvoice(false)}
        title="Invoice"
        deleteAction={DeleteInvoicePath}
      />
    </Form>
  );
};
