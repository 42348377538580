export const getFileExtension = (contentType: string) => {
  let fileFormat = "";
  switch (contentType) {
    case "image/jpeg":
      fileFormat = ".jpg";
      break;
    case "application/zip":
      fileFormat = ".zip";
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.ms-excel":
      fileFormat = ".xlsx";
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/msword":
      fileFormat = ".docx";
      break;
    case "application/pdf":
      fileFormat = ".pdf";
      break;
    case "image/png":
      fileFormat = ".png";
      break;
    case "application/x-rar-compressed":
    case "application/rar":
      fileFormat = ".rar";
      break;
    case "application/x-zip-compressed":
      fileFormat = ".zip";
      break;
  }

  return fileFormat;
};
